<template>
  <Layout>
    <div class="optcontainer regcontainer">
      <Header :title="content.o_title" :back="false" regulation="tcf20" />
      <h1 v-html="content.o_title" :dir="dir" />
      <div class="text" v-html="content.o_content" :dir="dir" />
      <div class="list" :dir="dir">
        <Purpose
          v-for="(purpose, i) in screen.purposes"
          :key="i"
          :purpose="purpose"
          :vendor-details="false"
        />
      </div>
      <Footer screen="options" />
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'
import Footer from '@components/tcf20/Footer.vue'

export default {
  metaInfo() {
    return {
      title: 'More Options | Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'More Options | Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      content: {},
      screen: {},
      dir: 'ltr',
    }
  },
  created() {
    // get screen display data
    this.screen = this.$compliance.getScreen('options')
    this.content = this.$compliance.getLocalisedContent()
    // retrieve & replace screen option data from GVL
    for (var purpose in this.screen.purposes) {
      // make sure we don't try this again if we already retireved purposes & replaced them
      if (!('name' in this.screen.purposes[purpose])) {
        try {
          this.screen.purposes[purpose] = {
            ...this.screen.purposes[purpose],
            ...this.$compliance.GVL.getPurpose(
              this.screen.purposes[purpose].type,
              this.screen.purposes[purpose].id
            ),
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  mounted() {
    this.$compliance.TCF20.setCurrentConsentScreen('custom')
    this.dir = this.$compliance.dir
  },
}
</script>

<style lang="scss">
.optcontainer {
  position: relative;
  background: #fff;
  padding: 10px 0 10px 0;
  width: 100%;

  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    margin: 0;
    padding: 20px 10px 30px 10px;
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  h1 {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 70px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .list {
    background: #fff;
    padding: 0 10px;
    margin: 20px 0 130px 0;
    @media screen and (min-width: 768px) {
      margin: 20px 0 200px 0;
    }

    h2 {
      font-size: 1.6em;
      color: #626d77;
      border-bottom: 1px solid #b8b8b8;
    }

    h3 {
      font-size: 1.2em;
      color: #626d77;
      margin: 10px 0 0 5px;
    }
  }
}
.ru .optcontainer h1 {
  font-size: 1.6em;
}
</style>
